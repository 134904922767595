import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';

const Datenschutz = ({ data }) => (
  <Layout
    title={data.datoCmsDatenschutz.seo && data.datoCmsDatenschutz.seo.title}
    description={data.datoCmsDatenschutz.seo && data.datoCmsDatenschutz.seo.description}
  >
    <div className="common-page">
      <Subheader title="DATENSCHUTZ" />

      <div className="container">
        <div className="row">
          <div
            className="col"
            dangerouslySetInnerHTML={{
              __html: data.datoCmsDatenschutz.text
            }}
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default Datenschutz;

export const query = graphql`
  query datoCmsDatenschutzQuery {
    datoCmsDatenschutz {
      text
      seo {
        title
        description
      }
    }
  }
`;
